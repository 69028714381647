import styled from 'styled-components';

export const siteWidth = '1100px';
export const contentBgColor = 'white';

export const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow-x: hidden;
  background-color: ${contentBgColor};
  min-height: 100vh;

  @media only screen and (min-width: 681px) {
    min-height: 100vh;
  }
`;

export const HeaderFullWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  background-color: ${contentBgColor};
  height: 90px;
  padding-top: 25px;

  @media only screen and (min-width: 1100px) {
    width: 1100px;
  }
`;

export const ContentWrapper = styled.div`
  width: ${siteWidth};
`;

export const MainContent = styled.section`
  width: 100%;

  @media only screen and (min-width: 1100px) {
    width: 1100px;
    padding: 15px 0;
  }

  @media only screen and (max-width: 680px) {
    width: 100%;
    max-width: 680px;
    padding: 10px;
  }
`;

export const FooterFullWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 1100px;
  height: 100%;
  color: #6b7684;
  margin: 0 auto;
  padding-top: 20px;

  @media only screen and (max-width: 680px) {
    width: 100%;
    max-width: 680px;
    margin: 0;
    font-size: 12px;
  }
`;

export const FlexWrapper = styled.div`
  display: flex;
`;

export const FlexSiteWidthWrapper = styled.section`
  display: flex;
  width: ${siteWidth};
`;

export const FlexColWrapper = styled(FlexWrapper)`
  flex-direction: column;
`;
