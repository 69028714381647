import MenuIcon from '@public/icons/menu2.svg';
import SearchIcon from '@public/icons/search2.svg';
import Link from 'next/link';
import React from 'react';
import styled from 'styled-components';

const MobileHeaderButtonWrapper = styled.div`
  height: 30px;

  .mobile-header-btn {
    width: 30px;
    height: 30px;

    &:last-child {
      margin-left: 10px;
    }
  }

  .search-icon {
    width: 30px;
    height: 30px;
  }
`;

const MobileHeaderButton = (props: MobileHeaderButtonProps) => {
  return (
    <>
      <MobileHeaderButtonWrapper>
        <Link passHref href="/business/search">
          <button className="mobile-header-btn">
            <SearchIcon className="search-icon" />
          </button>
        </Link>
        <a>
          <button onClick={props.clickTrigger} className="mobile-header-btn">
            <MenuIcon className="search-icon" />
          </button>
        </a>
      </MobileHeaderButtonWrapper>
    </>
  );
};

export default MobileHeaderButton;

type MobileHeaderButtonProps = {
  clickTrigger: () => void;
};
