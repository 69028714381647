import SearchIcon from '@public/icons/search2.svg';
import Link from 'next/link';
import React from 'react';
import styled from 'styled-components';

const PcSearchButton = () => {
  return (
    <>
      <SearchBtnWrapper>
        <Link passHref href="/business/search">
          <button id="header-search-btn-pc">
            <SearchIcon className="search-icon" />
            <span>도입기관 검색</span>
          </button>
        </Link>
      </SearchBtnWrapper>
    </>
  );
};

const SearchBtnWrapper = styled.div`
  padding-left: 20px;
  font-size: 15px;

  &:hover {
    color: #5368f5;

    .search-icon {
    }
  }

  #header-search-btn-pc {
    display: flex;
    align-items: center;
    border: 1px solid;
    border-radius: 15px;
    padding: 5px 15px;
  }

  .search-icon {
    width: 25px;
    height: 25px;
    margin-right: 5px;
  }
`;

export default PcSearchButton;
