import ExternalLink from '@public/icons/external-link.svg';

const ExternalLinkIcon = (props: ExternalLinkIconProps) => {
  return <ExternalLink style={{ width: props.size, height: props.size, marginLeft: 5, verticalAlign: 'text-top' }} />;
};

export default ExternalLinkIcon;

type ExternalLinkIconProps = {
  size: number;
};
