const CONFIG_LEVEL : 'local' | 'dev' | 'prd' = process.env.CONFIG_LEVEL;

type ConfigListType = {
  local: ConfigListDetailType;
  dev: ConfigListDetailType;
  prd: ConfigListDetailType;
};
type ConfigListDetailType = {
  enviornment: 'local' | 'dev' | 'prd';
  imageHost: string;
  linkHost: string;
  apiUrl: string;
  robot: {
    userAgent: string;
    content: string;
  };
  seo: {
    google: string;
    ga: string;
    gtm: string;
    naver: string;
    jennifer: string;
  };
};

const CONFIG_LIST: ConfigListType = {
  local: {
    enviornment: 'local',
    imageHost: 'https://localimage.dongbanmall.com/',
    linkHost: 'https://local.dongbanmall.com',
    apiUrl: 'https://devsupport.dongbanmall.com',
    robot: {
      userAgent: 'User-agent: *',
      content: 'Disallow: /',
    },
        seo: {
      google: '',
      ga: '',
      gtm: '',
      naver: '',
      jennifer: '',
    },
  },
  dev: {
    enviornment: 'dev',
    imageHost: 'https://devimage.dongbanmall.com/',
    linkHost: 'https://dev.dongbanmall.com',
    apiUrl: 'https://devsupport.dongbanmall.com',
    robot: {
      userAgent: 'User-agent: *',
      content: 'Disallow: /',
    },
        seo: {
      google: '',
      ga: '',
      gtm: '',
      naver: '',
      jennifer: '',
    },
  },
  prd: {
    enviornment: 'prd',
    imageHost: 'https://image.dongbanmall.com/',
    linkHost: 'https://www.dongbanmall.com',
    apiUrl: 'https://support.dongbanmall.com',
    robot: {
      userAgent: 'User-agent: *',
      content: 'Disallow: /admin/',
    },
    seo: {
      google: 'IrL_XihUSgQmoHfnAN-k9VLPBf6IQDOtk61r8YILRQM',
      ga: 'G-970XHMPHN4',
      gtm: 'GTM-W3CP29D',
      naver: '980f23c7a16a880f65b9a589b67a55f83be2fbdd',
      jennifer: '7984e0b7',
    },
  },
};

export const WEB_CONFIG = CONFIG_LIST[CONFIG_LEVEL];
