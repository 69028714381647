import FooterContents from './FooterContents';
import styled from 'styled-components';

const FooterWrapper = styled.footer`
  width: 100%;
  background: #323b4a;

  @media only screen and (max-width: 680px) {
    max-width: 680px;
    padding: 0 10px;
  }

  @media only screen and (min-width: 681px) {
    height: 225px;
  }
`;

const Footers = () => {
  return (
    <FooterWrapper>
      <FooterContents />
    </FooterWrapper>
  );
};

export default Footers;
