import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import styled from 'styled-components';

const SubMenuNav = (props: SubMenuPropsType) => {
  const router = useRouter();

  const SubMenuItems = (subMenu: Array<SubMenuType>) => {
    return subMenu.map((x: any) => {
      return (
        <Link passHref href={x.link} key={x.key}>
          <SubMenuItem
            color={router.route === x.link ? '#5368F5' : 'white'}
            background={router.route === x.link ? 'white' : '#5368F5'}>
            {x.name}
          </SubMenuItem>
        </Link>
      );
    });
  };

  return (
    <SubMenuWrapper opacity={props.opacity}>
      <nav className="sub-menu-nav">{SubMenuItems(props.subMenu)}</nav>

      <style jsx>
        {`
          .sub-menu-nav {
            width: 1100px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
          }
        `}
      </style>
    </SubMenuWrapper>
  );
};

export default SubMenuNav;

const SubMenuWrapper = styled.div`
  @media only screen and (min-width: 681px) {
    background: #5368f5;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 60px;
    padding: 10px 0;
    opacity: ${(props: SubMenuWrapperProps) => props.opacity};
    justify-content: center;
    align-items: center;
    color: white;
    transition: all 0.3s;
  }
`;

const SubMenuItem = styled.a`
  margin-right: 10px;
  padding: 5px 20px;
  border-radius: 10px;
  transition: all ease 0.3s 0s;
  cursor: pointer;
  background: ${(props: SubMenuItemProps) => props.background};
  color: ${(props: SubMenuItemProps) => props.color};

  &:hover {
    background: white;
    color: #5368f5;
  }
`;

type SubMenuWrapperProps = {
  opacity: number;
};

type SubMenuItemProps = {
  background?: string;
  color?: string;
};

type SubMenuPropsType = {
  subMenu: any;
  opacity: number;
};

export type SubMenuType = {
  key: string;
  name: string;
  link: string;
};
