import { motion } from 'framer-motion';
import Link from 'next/link';
import styled from 'styled-components';

const SubMenuItem = styled.span`
  width: 100%;
  height: 30px;
  border-radius: 10px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 10px;

  &:first-child {
    margin-top: 15px;
  }
`;

const AccordionContentComponent = (props: any) => {
  return (
    <motion.div layout initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      {props.subMenu.map((x: any) => (
        <SubMenuItem key={x.key} onClick={props.closeMobileMenu}>
          <Link href={x.link}>{x.name}</Link>
        </SubMenuItem>
      ))}
    </motion.div>
  );
};

export default AccordionContentComponent;
