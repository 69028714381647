import AccordionItemComponent from '@components/common/mobile-menu/AccordionItemComponent';
import { TopMenu, TopMenuItem } from '@components/header/Header';
import ExternalLinkIcon from '@components/icon/ExternalLinkIcon';
import MenuData from '@data/menu.json';
import { LayoutGroup, motion } from 'framer-motion';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import styled from 'styled-components';

interface MobileMenuContainerProps {
  readonly mobile: boolean;
}

const MobileMenuContainer = styled.div<MobileMenuContainerProps>`
  @media only screen and (max-width: 680px) {
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(10px);
    transition: 0.4s;
    position: fixed;
    width: 100%;
    max-width: 680px;
    right: ${(props) => (props.mobile === true ? '0' : '-100%')};
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    min-height: 100vh;

    ul,
    li {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    ul {
      display: flex;
      flex-direction: column;
      background: white;
      padding: 20px;
      border-radius: 25px;
    }

    li {
      background-color: rgba(214, 214, 214, 0.5);
      border-radius: 10px;
      padding: 20px;
      margin-bottom: 20px;
      overflow: hidden;
      cursor: pointer;
    }

    li:last-child {
      margin-bottom: 0;
    }
  }
`;

const CloseButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 5px 30px 0;
  height: 75px;

  img {
    width: 150px;
    height: 27px;
  }
`;

const CloseButton = styled.button`
  border: 1px solid;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`;

const MenuContainer = styled.div`
  padding: 10px 0;
  height: calc(100vh - 90px);
  overflow-y: scroll;
`;

const TOP_MENU: TopMenu = MenuData.filter((x: TopMenu) => x.type == 'top-menu')[0];

const MobileMenuComponent = forwardRef(function Ref(props, ref) {
  useImperativeHandle(ref, () => ({
    openMobileMenu() {
      setMobile(true);
      document.body.style.overflow = 'hidden';
      document.body.style.zIndex = '1';
    },
  }));
  const [mobile, setMobile] = useState(false);

  const closeMobileMenu = () => {
    setMobile(false);
    document.body.style.overflow = 'unset';
  };

  return (
    <MobileMenuContainer mobile={mobile}>
      <CloseButtonWrapper>
        <img src="/assets/logo-white.png" alt="동반성장몰 모바일 메뉴 로고" />
        <CloseButton onClick={closeMobileMenu}>
          <FaTimes />
        </CloseButton>
      </CloseButtonWrapper>
      <LayoutGroup>
        <MenuContainer>
          <motion.ul layout initial={{ borderRadius: 25 }}>
            {TOP_MENU.data.map((x: TopMenuItem) => {
              return x.type === 'internal' ? (
                <AccordionItemComponent
                  closeMobileMenu={closeMobileMenu}
                  topMenu={x.name}
                  subMenu={x.sub}
                  key={x.key}
                />
              ) : (
                <li key={x.key}>
                  <a href={x.link} target={x.target} style={{ display: 'block' }}>
                    {x.name} <ExternalLinkIcon size={20} />
                  </a>
                </li>
              );
            })}
          </motion.ul>
        </MenuContainer>
      </LayoutGroup>
    </MobileMenuContainer>
  );
});
export default MobileMenuComponent;
