import { FooterFullWrapper } from '@styles/components';
import styled from 'styled-components';

const CompanyTitle = styled.h2`
  color: #b0b8c1;
  font-size: 20px;
  font-weight: bold;

  @media only screen and (max-width: 680px) {
    font-size: 18px;
  }
`;

const DescriptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 20px 0;

  @media only screen and (max-width: 680px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 0;
  }
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 15px;

  @media only screen and (max-width: 680px) {
    font-size: 12px;
  }
`;

const CS = styled.div`
  .cs-title {
    font-size: 22px;
    color: #b0b8c1;

    @media only screen and (max-width: 680px) {
      font-size: 16px;
    }
  }

  .cs-number {
    font-size: 18px;
    margin-bottom: 0;

    @media only screen and (max-width: 680px) {
      font-size: 14px;
    }
  }

  @media only screen and (max-width: 680px) {
    margin-top: 15px;
  }
`;

const CopyRight = styled.div`
  border-top: 1px solid;
  padding: 20px 0;
`;
const FooterContents = () => {
  return (
    <FooterFullWrapper>
      <CompanyTitle>중소기업유통센터</CompanyTitle>
      <DescriptionWrapper>
        <Description>
          <span>서울특별시 양천구 목동동로 309(행복한백화점) 중소기업유통센터</span>
          <span>대표이사 : 이태식 | 개인정보보호책임자 : 이성복</span>
          <span style={{ wordBreak: 'break-all' }}>
            사업자등록번호 : 107-81-53660 | 통신판매업신고 : 제2016-서울양천-00030호
          </span>
        </Description>
        <CS>
          <p className="cs-title">고객센터</p>
          <p className="cs-number">1522-6009 (평일 09시 ~ 18시)</p>
        </CS>
      </DescriptionWrapper>
      <CopyRight>
        <span>COPYRIGHT © 2023 SMALL & MEDIUM BUSINESS DISTRIBUTION CENTER. ALL RIGHTS RESERVED.</span>
      </CopyRight>
    </FooterFullWrapper>
  );
};

export default FooterContents;
