import AccordionContentComponent from '@components/common/mobile-menu/AccordionContentComponent';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';

const AccordionItemComponent = (props: any) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <motion.li layout initial={{ borderRadius: 10 }}>
      <motion.div layout onClick={toggleOpen}>
        {props.topMenu}
      </motion.div>
      <AnimatePresence>
        {isOpen && <AccordionContentComponent closeMobileMenu={props.closeMobileMenu} subMenu={props.subMenu} />}
      </AnimatePresence>
    </motion.li>
  );
};

export default AccordionItemComponent;
