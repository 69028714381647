import LogoImage from '@public/assets/logo.png';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import styled from 'styled-components';

const Logo = () => {
  return (
    <LogoWrapper>
      <Link href="/">
        <a id="logo-link">
          <Image src={LogoImage} alt="동반성장몰 로고" />
        </a>
      </Link>
    </LogoWrapper>
  );
};

export default Logo;

const LogoWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;

  #logo-link {
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;

    @media only screen and (max-width: 680px) {
      width: 150px;
    }
  }
`;
