import Footers from '@components/footer/Footers';
import Header from '@components/header/Header';
import { LayoutContainer } from '@styles/components';
import React from 'react';
import styled from 'styled-components';

const Main = styled.main`
  width: 100%;
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 680px) {
    max-width: 680px;
    padding-top: 60px;
  }

  @media only screen and (min-width: 681px) {
    min-height: calc(100vh - 345px);
  }
`;

type AppLayoutProps = {
  children: React.ReactNode;
};

function AppLayout({ children }: AppLayoutProps) {
  return (
    <LayoutContainer>
      <Header />
      {/* <Main children={children} /> */}
      <Main>{children}</Main>
      <Footers />
    </LayoutContainer>
  );
}

export default AppLayout;
