import styled from 'styled-components';

export const HeaderWrapper = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 20px;
  transition: height 0.5s;
  z-index: 10;
  top: 0;
  background: white;
  box-shadow: ${(props: HeadWrapperProps) =>
    props.isSubMenuHide ? '0 -2px 12px 0 rgb(0 0 0 / 6%), 0 -1px 4px 0 rgb(0 0 0 / 18%)' : 'none'};

  @media only screen and (max-width: 680px) {
    height: 60px;
    padding-top: 0;
    position: fixed;
    border-bottom: 1px solid rgba(0, 27, 55, 0.1);
  }

  @media only screen and (min-width: 681px) {
    height: ${(props: HeadWrapperProps) => props.height};
    position: sticky;
  }

  #header-area {
    display: flex;
    flex-direction: row;
    width: 1100px;
    height: 60px;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: 680px) {
      width: 100%;
      padding: 0 10px;
    }
  }

  #top-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    font-size: 20px;
  }
  .mobile-btn-wrapper,
  .mobile-menu-btn,
  .mobile-menu-icon {
    width: 30px;
    height: 30px;
  }
`;

export const TopMenuItem = styled.div`
  @media only screen and (min-width: 681px) {
    padding: 0 20px;
    font-weight: 600;
    cursor: pointer;

    &:hover {
      button {
        padding-bottom: 10px;
        border-bottom: 2px solid #5368f5;
      }
    }

    button{
      transition: all ease 0.3s 0s;
      border-bottom: ${(props: TopMenuItemProps) => props.bb};
      padding-bottom: ${(props: TopMenuItemProps) => props.pb};

      &:focus {
        padding-bottom: 10px;
        border-bottom: 2px solid #5368f5;
      }
    }
  }
`;

export type TopMenuItemProps = {
  pb?: string;
  bb?: string;
};

type HeadWrapperProps = {
  height: string;
  isSubMenuHide: boolean;
};
